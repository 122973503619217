module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HW72EJBH38"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1380,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"}],"remarkPlugins":[null]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":false,"prismPreset":{"color":"#393A34","backgroundColor":"#f6f8fa",".comment,.prolog,.doctype,.cdata":{"color":"#999988","fontStyle":"italic"},".namespace":{"opacity":0.7},".string,.attr-value":{"color":"#e3116c"},".punctuation,.operator":{"color":"#393A34"},".entity,.url,.symbol,.number,.boolean,.variable,.constant,.property,.regex,.inserted":{"color":"#36acaa"},".atrule,.keyword,.attr-name,.selector":{"color":"#00a4db"},".function,.deleted,.tag":{"color":"#d73a49"},".function-variable":{"color":"#6f42c1"},".tag,.selector,.keyword":{"color":"#00009f"},".highlight":{"background":"hsla(0, 0%, 70%, .5)"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
